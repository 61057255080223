.linked-list {
  .list-group-item {
    margin: 0.6rem 0 0;
    background-color: rgba($grey-light, 0.5);
    border-radius: 0;
    border: 0;
    transition: all 0.2s ease 0s;
    padding: 0;

    svg:not(.status-icon) {
      margin-right: 2rem;
      margin-left: 0;
      stroke: $grey-dark;
      transition: all 0.2s ease 0s;
    }

    &:hover svg:not(.status-icon) {
      stroke: $primary;
      margin-left: 1rem;
    }

    a {
      display: block;
      padding: 0.5rem 1.25rem;
      color: $grey-darker;
      font-size: 0.95rem;

      &:hover {
        color: $primary;
        text-decoration: none;
      }

      .label {
        vertical-align: text-top;
      }
    }
  }
}

.actions-list {

  .actions-list-item {
    opacity: 1;
    transition: all 0.2s ease 0s;

    &.isRemoving {
      opacity: 0;
    }

    .status-icon {
      margin-right: 0.5rem;
    }
  }


  .actions-list-actions {
    margin: 0.6rem 1.5rem 0 0;

    .action {
      padding: 0.5rem 1rem;
      margin-right: 1rem;
      background-color: rgba($grey-light, 0.5);
      overflow: hidden;
      border: 0;
      text-decoration: none;
      color: $grey;
      transition: all 0.2s ease 0s;

      &:last-of-type {
        margin-right: 0;
      }

      svg {
        transition: all 0.2s ease 0s;
      }

      .label {
        display: inline-block;
        vertical-align: text-top;
        opacity: 0;
        max-width: 0;
        transition: all 0.4s ease 0s;
      }

      &.active {
        color: $red;

        svg {
          fill: $red;
          stroke: $red;
        }

        .label {
          opacity: 1;
          padding-left: 1rem;
          max-width: 100px;
        }
      }
      
    }
  }
  
}