.favorites {
  .show-actions {
    svg {
      stroke: $grey-dark;
      transition: all 0.3s ease;
    }

    &:hover {
      svg {
        stroke: $white;
      }
    }
  }
}

.favorite-button {
  background-color: $grey-light;
  border: 0;
  color: $grey-dark;
  vertical-align: top;

  &:hover {
    background-color: $grey-light;
    color: $grey-dark;
  }

  svg {
    margin-right: 0.5rem;
    margin-top: -0.2rem;

    * {
      fill: $grey-dark;
    }
  }
}
