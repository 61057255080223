$card-border-radius: 5px;

.card {
  border: none;
  box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.16);
  border-radius: $card-border-radius;

  .card-header {
    border-top-right-radius: $card-border-radius;
    border-top-left-radius: $card-border-radius;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 1.5rem 2rem 0;
    background-color: $white;
    color: $grey-dark;
    border-bottom: 0;
  }

  .card-body {
    padding: 2rem;
  }

  .card-footer {
    border-bottom-right-radius: $card-border-radius;
    border-bottom-left-radius: $card-border-radius;
    padding: 1rem 2rem;
    background-color: $white;
  }
}