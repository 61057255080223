.form-wrapper {
  max-width: 457px;
}

.login,
.first-login-prompt {
  .logo {
    width: 15rem;
  }

  .btn-link {
    color: $primary;
  }

  input {
    border-radius: 0;

    &::placeholder {
      color: $grey;
    }
  }

  input#password {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .form-group {
    text-align: left;
  }
}

.login {
  .form-wrapper {
    padding: 1rem 5rem;
    border: 2px solid $grey-light;
    border-radius: 8px;
    background-color: $white;
  }
  @media (max-width: 991.98px) {
    form {
      padding: 1rem 2rem;
    }
  }
}

.first-login-prompt {
  .set-password {
    padding: 1rem 2rem;

    form {
      margin-bottom: 2rem;
    }
  }
}
