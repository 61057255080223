@import "./theme.scss";

html,
body,
#root {
  height: 100%;
}

body {
  font-family: Etelka;
  color: $grey-darker;
  background-color: $grey-lighter;
}

.main {
  padding: 0;
}

.get-app-banner button {
  width: 100%;
  padding: 20px;
  background-color: $grey-lighter;
}

.password-form {
  min-height: 50vh;

  .password-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.reset-passwrod-form {
  width: 50%;
}

.footer {
  line-height: 2rem; /* Vertically center the text there */
  margin: 3rem 0;
  width: 100%;

  ul {
    margin: 0;
    padding: 0;
  }

  a {
    color: $grey-dark;
  }

  @media (max-width: 991.98px) {
    ul {
      flex-direction: column;
    }
  }
}

.language-switcher {
  line-height: 60px; /* Vertically center the text there */
}

.success {
  color: $green;
}

.error {
  color: $red;
}

.emphasized {
  font-size: 22px;
  font-weight: bold;
}

@media (max-width: 991.98px) {
  .content {
    margin-top: 1rem;
  }

  h1,
  .h1 {
    font-size: 2rem;
  }

  h2,
  .h2,
  h3,
  .h3 {
    font-size: 1.5rem;
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($color: $grey-lighter, $alpha: 0.8);
  z-index: 100;
}

svg {
  &.green {
    stroke: $green;
  }

  &.red {
    stroke: $red;
  }

  &.yellow {
    stroke: $yellow;
  }

  &.primary {
    stroke: $primary;
  }

  &.grey {
    stroke: $grey;
  }
}

.form-check-label:hover,
.form-check-input:hover {
  cursor: pointer;
}

.header-toolbar {
  svg,
  .spinner-border {
    margin: 0 0.4rem 0.2rem 0;
  }
}

@import "./login";
@import "./navbar";
@import "./autocomplete";
@import "./search-results";
@import "./recommendation";
@import "./report";
@import "./settings";
@import "./tutorial";
@import "./cookie-consent";
@import "./information";
@import "./right-sidebar";
@import "./favorites";
@import "./lists";
@import "./tables";
@import "./card";
@import "./feedback";
@import "./notification-alert";
