@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

$primary: #0f73c2;
$grey: #718096;
$grey-light: #E2E8F0;
$grey-lighter: #F7FAFC;
$grey-dark: #4A5568;
$grey-darker: #2D3748;
$secondary: $grey-darker;
$white: #ffffff;
$red: #fc0f2b;
$red-light: lighten($red, 15%);
$red-dark: darken($red, 30%);
$yellow: #fec829;
$green: #39b039;
$primary-light: lighten($primary, 20%);
$primary-lighter: lighten($primary-light, 10%);
// $enable-rounded: false;

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  light-grey: $grey-light,
  grey: $grey,
  dark-grey: $grey-dark,
  white: $white,
  red: $red,
  yellow: $yellow,
  green: $green
);

@font-face {
  font-family: "Etelka";
  font-style: normal;
  font-weight: 400;
  src: local("EtelkaLight"),
    url(./../assets/fonts/EtelkaLight.otf) format("opentype");
}

@font-face {
  font-family: "Etelka";
  font-style: italic;
  font-weight: 400;
  src: local("EtelkaLightItalic"),
    url(./../assets/fonts/EtelkaLightItalic.otf) format("opentype");
}

@font-face {
  font-family: "Etelka";
  font-style: normal;
  font-weight: 600;
  src: local("EtelkaMedium"),
    url(./../assets/fonts/EtelkaMedium.otf) format("opentype");
}

@font-face {
  font-family: "Etelka";
  font-style: italic;
  font-weight: 600;
  src: local("EtelkaMediumItalic"),
    url(./../assets/fonts/EtelkaMediumItalic.otf) format("opentype");
}

@import "node_modules/bootstrap/scss/bootstrap";
