.information {
  h1,
  p {
    margin-bottom: 2rem;
  }

  h2 {
    margin-bottom: 1rem;
  }

  @media (max-width: 991.98px) {
    padding: 2rem;
  }
}
