.notification-alert {
  position: fixed;
  top: 50px;
  right: 50px;
  z-index: 10;
  color: $red-dark;
  background-color: rgba($color: $red-light, $alpha: 0.5);

  .toast-header  {
    background-color: transparent;
    border: 0;

    .close {
      color: $red-dark;
    }
  }
}