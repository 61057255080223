/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  tr {
    &:nth-child(odd) {
      background-color: $grey-light;
    }
  }
}

.data-table {
  .table {
    th {
      &.asc::after {
        content: "\25B2";
        margin-left: 0.5rem;
      }
      &.desc::after {
        content: "\25BC";
        margin-left: 0.5rem;
      }
    }

    .link {
      padding: 0;
    }
  }

  .hidden {
    visibility: hidden;
  }

  @media only screen and (min-width: 992px) {
    thead {
      tr {
        display: flex;

        &:after {
          content: "";
          overflow-y: scroll;
          visibility: hidden;
        }
      }

      th {
        flex: 1 auto;
        display: block;
        width: 100%;
      }
    }

    tbody {
      display: block;
      width: 100%;
      overflow-y: auto;
      height: auto;

      tr {
        display: flex;

        td {
          flex: 1;
          word-wrap: break-word;
        }
      }
    }
  }
}
