.full-report {
  .header {
    justify-content: space-between;
    padding: 20px 0;
  }

  .logo {
    height: 100px;
  }

  .company-name {
    display: flex;
    flex-direction: column;
    text-align: right;
    font-weight: bold;
    font-size: 16px;
    color: $blue;

    .sub {
      font-size: 12px;
      font-weight: normal;
      color: $red;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    margin-top: 20px;
  }

  h1:first-of-type {
    margin-top: 0;
  }

  h1,
  h2,
  h3 {
    font-size: 20px;
  }

  .report-footer {
    font-size: 12px;
    line-height: 12px;

    div {
      display: flex;
      flex-direction: column;
      width: 25%;
    }
  }

  .header,
  .report-footer {
    display: none;
  }

  .report-footer div {
    padding: 1rem 0.5rem 0;

    &:first-of-type,
    &:last-of-type {
      padding-left: 0;
    }
  }

  details {
    max-width: 100%;
    overflow-x: auto;
  }

  [data-segment-key="appendix"],
  [data-segment-key="disclaimer"] {
    details {
      padding-top: 20px;

      summary {
        font-weight: bold;
      }
    }
  }

  table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;

    th,
    td {
      padding: 0.3rem;
      border-top: 1px solid $grey;

      p {
        margin: 0;
      }
    }

    thead th {
      vertical-align: bottom;
      border-bottom: 2px solid $grey;
    }
  }

  .hidden h2,
  h1:not(.heading) {
    display: none;
  }

  [data-segment-key="summary"] {
    & > * {
      display: none;
    }

    & > h1:first-of-type {
      display: block;
      font-size: 1rem;
      font-weight: normal;
    }
  }

  .download-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  @media (max-width: 991.98px) {
    padding: 1rem 2rem;
  }

  @media only screen and (min-width: 992px) {
    thead {
      tr {
        display: flex;

        &:after {
          content: "";
          overflow-y: scroll;
          visibility: hidden;
        }
      }

      th {
        flex: 1 auto;
        display: block;
        width: 100%;
      }
    }

    tbody {
      display: block;
      width: 100%;
      overflow-y: auto;
      height: auto;
      max-height: 600px;

      tr {
        display: flex;

        td {
          flex: 1;
          word-wrap: break-word;
        }
      }
    }
  }
}

@page {
  size: auto; /* auto is the initial value */

  /* this affects the margin in the printer settings */
  margin: 20mm;
}

@media print {
  body * {
    visibility: hidden;
  }
  body {
    line-height: 1.3rem;
    font-size: 14pt;
  }
  .full-report,
  .full-report * {
    visibility: visible;
  }

  .full-report .print-report,
  .btn {
    display: none;
  }
  .full-report {
    color: #000;
    position: absolute;
    left: 0;
    top: 0;
  }

  .main,
  .sidebar {
    flex: none;
    max-width: none;
  }

  a {
    color: #000;
    text-decoration: none !important;
  }

  th,
  td {
    border-color: #000 !important;
  }

  .header,
  .report-footer {
    display: flex !important;
  }
}
