.nav-item .label {
  cursor: pointer;
}

.tutorial {
  .carousel-item {
    height: 70vh;
    overflow-y: auto;
  }
  .carousel-controls {
    padding: 1rem;
  }
}
