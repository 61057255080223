.search-results {
  margin-top: 1rem;

  .autocomplete {
    label {
      display: none;
    }
  }
}

.search-result-list {
  margin: 0 4rem 6rem;

  @media (max-width: 991.98px) { 
    margin: 0 0 6rem;
  }
}

.search-result-item {
  display: block;
  background-color: $white;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.16);
  margin: 1.5rem 0;

  .card {

    .card-header {
      padding: 1.5rem 3rem 0.5rem;
      color: $grey-darker;
      font-size: 1.5rem; 
      
      .search-result-header__label {
        text-transform: uppercase;
        color: $grey-dark;
        font-size: 1rem;
        font-weight: normal;
        margin-right: 0.6rem;
      }
    }

    .card-body {
      padding: 1.5rem 3rem;
      color: $grey-dark;
    }

    .card-footer {
      margin-top: 1rem;
      padding: 0;
      border-top: 1px solid $grey-lighter;

      .view-more-btn, .view-less-btn {
        background-color: transparent;
        border: none;
        color: $grey-dark;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      .view-more-btn {
        transition: all 200ms ease-in-out;

        .view-more-icon {
          opacity: 0.5;
          transition: all 300ms ease-in-out;

          * {
            stroke: $grey-dark;
          }
        }

        &:hover {
            padding-bottom: 1.5rem;

          .view-more-icon {
            margin-top: 1rem;
            opacity: 1;
          }
        }
      }

      .view-less-btn {
        border-radius: 0;
        opacity: 0.6;
        transition: opacity 200ms ease-in-out;

        .view-less-icon {
          margin-left: -2rem;
          margin-right: 1rem;

          * {
            stroke: $grey-dark;
          }
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .search-result-content__box {
    background-color: $grey-lighter;
    border-radius: 5px;
    padding: 1.5rem;
    color: $grey-darker;

    @include media-breakpoint-down(sm) {
      padding: 2rem 1rem;
    }
  }

  .linkage-line {
    display: flex;
    align-content: center;
    height: 2px;
    width: 100%;
    background-color: $grey-light;
  }



  .search-result__icon-container {

    .status-icon {
      width: 3.5rem;
      height: auto;
      margin: 1.5rem 0;
      padding: 1.5rem;
      box-sizing: content-box;
      * {
        fill: $white;
      }

    }

    @include media-breakpoint-down(sm) {
      margin-top: -1rem;
      margin-bottom: -1rem;
      z-index: 2;
    }
  }

  .search-result-content__label {
    color: lighten($grey-darker, 5%);
    font-size: 90%;
    text-transform: uppercase;
  }

  &:hover {
    text-decoration: none;
  }
}

.legend {
  font-size: 0.9rem;

  .btn-link {
    color: $grey-dark;
    text-decoration: none;
  }
}

.search-toolbar {
  margin-bottom: 2rem;

  .search-toolbar-module {
    padding: 1rem 0;
  }

  .active-ingredient {
    color: $grey-darker;
    font-weight: bold;
    margin-left: 1rem;
    padding: .5rem 1rem;
    border-radius: .5rem;
    background-color: $grey-light;
    transition: all 0.3s ease;

    &:hover {
      text-decoration: none;
    }

    &.deselected {
      background-color: transparent;
      text-decoration: line-through;
      border-color: $grey-light;
      color: $grey;

      svg * {
        stroke: $grey;
      }
    }

    svg {
      width: 1rem;
      height: auto;
      margin-bottom: .2rem;
      margin-right: 0.5rem;

      * {
        fill: transparent;
        stroke: $grey-darker;
      }
    }
  }

}