.right-sidebar {
  @media (min-width: 992px) {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    border-left: 1px solid $grey-lighter;
  }

  @include media-breakpoint-down(md) {
    border-top: 1px solid $grey-light;
  }

  background-color: $white;

  .powered-by {
    margin: 2rem auto;
    width: 100%;
    max-width: 150px;
    text-align: center;

    span {
      margin-right: 1rem;
    }
  }
}
