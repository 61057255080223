.recommendation {

  // border-top: 1px solid $grey-lighter;
  // padding-top: 2rem;

  .back-button {
    display: flex;
    align-items: center;
    padding: 30px;
    border-right: 1px solid $grey-lighter;

    &:hover svg {
      stroke: $primary;
    }
  }

  .suggestion-text {
    padding: 20px 30px;
    border-bottom: 1px solid $grey-lighter;
  }

  .recommendation-detail {
    .nav-tabs {
      padding: 0;
      font-size: 22px;
      font-weight: bold;
      background-color: $white;
      border-top: 1px solid $grey-lighter;
      color: $grey-dark;
    }

    .nav-item {
      padding: 0 1.5rem;
    }

    .nav-link {
      padding: 10px 0;
      cursor: pointer;
      border: solid transparent;
      border-width: 0 0 2px 0;
    }

    .nav-link:hover,
    .nav-link.active {
      color: $primary;
      border-color: $primary;
    }

    .tab-content {
      background-color: $white;
      padding: 2rem;

      ul {
        list-style-type: disc;
        padding-left: 15px;
      }
    }

    .collapse-header {
      padding: 1rem 3rem;
      border-bottom: 1px solid $grey-lighter;
      margin-bottom: 1rem;
      color: $grey-dark;

      &:hover {
        cursor: pointer;
      }

      &.active {
        color: $primary;
        border-color: $primary;
      }
    }

    .collapse {
      padding: 1rem 3rem;
    }
  }

  .alert {
    border: 1px solid $grey-light;

    svg * {
      stroke: $primary;
    }
  }
}
